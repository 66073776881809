<template>
  <CRow>
    <CCol col="12" sm="12">
      <CCard>
        <CCardHeader>
          {{ $route.name }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" sm="5">
              <CInput
                :placeholder="$t('search_a') + $t('serial_number')"
                v-model="searchData.sn"
                v-on:keyup.enter="getProductSerialNumbers()"
              />
            </CCol>
            <CCol col="12" sm="5">
              <CSelect
                :placeholder="$t('search_a') + $t('registration_status')"
                :options="registeredOptions"
                :value.sync="searchData.registered"
              />
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="primary" block @click="getProductSerialNumbers()">{{ $t('search') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <CRow>
            <CCol col="12" sm="8" class="d-flex align-items-center">
              <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="info" block @click="() => batchSerialNumberModal = { modal: true }">{{ $t('batch_number') }}</CButton>
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="info" block @click="() => createSerialNumberModal = { modal: true }">{{ $t('create_serial_number') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <v-client-table :columns="columns" :data="data" :options="options">
            <font slot="sn" slot-scope="props">
              {{ props.row.sn_suffix ? props.row.sn + '-' + props.row.sn_suffix : props.row.sn }}
            </font>
            <CBadge slot="registered" slot-scope="props" :color="$_.find(registeredOptions, option => { return option.value === props.row.registered }).color">
              {{ $_.find(registeredOptions, option => { return option.value === props.row.registered }).label }}
            </CBadge>
            <div slot="action" slot-scope="props" class="text-center">
              <CButtonGroup>
                <CButton color="warning" v-bind="{ variant: 'outline' }" @click="() => editSerialNumberModal = { data: props.row, modal: true }">{{ $t('edit') }}</CButton>
                <CButton color="danger" v-bind="{ variant: 'outline' }" @click="() => deleteSerialNumberModal = { data: props.row, modal: true }">{{ $t('delete') }}</CButton>
              </CButtonGroup>
            </div>
          </v-client-table>
        </CCardBody>
      </CCard>

      <BatchSerialNumberModal :data="batchSerialNumberModal.data" :show="batchSerialNumberModal.modal" @showUpdate="show => batchSerialNumberModal.modal = show" />

      <CreateSerialNumberModal :data="createSerialNumberModal.data" :show="createSerialNumberModal.modal" @showUpdate="show => createSerialNumberModal.modal = show" />

      <EditSerialNumberModal :data="editSerialNumberModal.data" :show="editSerialNumberModal.modal" @showUpdate="show => editSerialNumberModal.modal = show" />

      <DeleteSerialNumberModal :data="deleteSerialNumberModal.data" :show="deleteSerialNumberModal.modal" @showUpdate="show => deleteSerialNumberModal.modal = show" />

    </CCol>
  </CRow>
</template>

<script>
import BatchSerialNumberModal from './modal/BatchSerialNumberModal'
import CreateSerialNumberModal from './modal/CreateSerialNumberModal'
import EditSerialNumberModal from './modal/EditSerialNumberModal'
import DeleteSerialNumberModal from './modal/DeleteSerialNumberModal'

export default {
  name: 'serialNumbers',
  components: {
    BatchSerialNumberModal,
    CreateSerialNumberModal,
    EditSerialNumberModal,
    DeleteSerialNumberModal
  },
  data () {
    return {
      columns: [ 'sn', 'name', 'phone', 'email', 'registered', 'action' ],
      data: [],
      options: {
        headings: {
          sn: this.$t('serial_number'),
          name: this.$t('name'),
          phone: this.$t('phone'),
          email: this.$t('email'),
          registered: this.$t('registration_status'),
          action: this.$t('action')
        },
        sortable: [ 'sn', 'name', 'phone', 'email', 'registered' ],
        filterable: [ 'sn', 'name', 'phone', 'email' ]
      },
      registeredOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('registration_status') + '...' },
        { value: 1, label: this.$t('registered'), color: 'success' },
        { value: 0, label: this.$t('unregistered'), color: 'danger' }
      ],
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: { sn: '', registered: null },
      batchSerialNumberModal: { data: {}, modal: false },
      createSerialNumberModal: { data: {}, modal: false },
      editSerialNumberModal: { data: {}, modal: false },
      deleteSerialNumberModal: { data: {}, modal: false },
    }
  },
  computed: {
  },
  mounted: function () {
  },
  methods: {
    getProductSerialNumbers () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.$store.dispatch('getProductSerialNumbers', this.$_.clone(this.searchData)).then(res => {
        this.data = res
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    }
  }
}
</script>
