<template>
  <CModal
    :title="$t('create_serial_number')"
    size="xl"
    color="info"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('serial_number')"
          :placeholder="$t('serial_number')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.sn"
          :isValid="valid_formData_sn"
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('suffix')"
          :placeholder="$t('suffix')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.sn_suffix"
          :isValid="valid_formData_sn_suffix"
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('name')"
          :placeholder="$t('name')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.name"
          :isValid="valid_formData_name"
        />
      </CCol>
      <CCol col="12" sm="12">
        <CSelect
          :label="$t('calling_code')"
          :options="regionsOptions"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="formData.calling_code"
          :isValid="valid_formData_calling_code"
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('phone')"
          :placeholder="$t('phone')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.phone"
          :isValid="valid_formData_phone"
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('email')"
          :placeholder="$t('email')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.email"
          :isValid="valid_formData_email"
        />
      </CCol>
    </CRow>
    <CButton slot="footer" color="primary" block @click="submit()">{{ $t('save') }}</CButton>
  </CModal>
</template>

<script>
export default {
  name: 'CreateSerialNumberModal',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      regionsOptions: [],
      formData: {},
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.getRegions()
        this.formData = { sn: '', sn_suffix: '', name: '', calling_code: '+886', phone: '', email: '' }
      }
    }
  },
  computed: {
    valid_formData_sn () {
      return /^\d{5}$/.test(this.formData.sn)
    },
    valid_formData_sn_suffix () {
      return !this.formData.sn_suffix || /^\d*$/.test(this.formData.sn_suffix)
    },
    valid_formData_name () {
      return this.formData.name ? true : false
    },
    valid_formData_calling_code () {
      return this.formData.calling_code ? true : false
    },
    valid_formData_phone () {
      return /^\d+$/.test(this.formData.phone) ? true : false
    },
    valid_formData_email () {
      return /^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/.test(this.formData.email)
    },
    valid_submit () {
      return this.valid_formData_sn && this.valid_formData_name && this.valid_formData_calling_code && this.valid_formData_phone && this.valid_formData_email
    }
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    formSubmit (submit, e) {
      this.$emit('formSubmit', submit, e)
    },
    getRegions () {
      const loader = this.$loading.show()
      this.$store.dispatch('getRegions').then(res => {
        this.regionsOptions = []
        for (const i in res) {
          this.regionsOptions.push({ value: res[i].calling_code, label: (this.$store.getters.locale === 'tw' ? res[i].zh_name : res[i].en_name) + ' (' + res[i].calling_code + ')' })
        }
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    submit () {
      if (this.valid_submit) {
        const loader = this.$loading.show()
        this.$store.dispatch('storeProductSerialNumber', this.$_.clone(this.formData)).then(res => {
          this.formSubmit(true)
          this.modal.show = false
          this.showUpdate(false)
          this.$swal('Success', this.$t('created_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
  },
  beforeDestroy: function () {
  },
}
</script>
